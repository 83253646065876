<template>
  <div id="app">
    <el-container>
      <el-header>
        <div class="logo">
          <img src="http://system.yxht.net/logo_1.png" alt />
        </div>
        <el-menu
          :default-active="$route.path"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          router
        >
          <el-menu-item index="/">集团首页</el-menu-item>
          <el-menu-item index="/about">关于我们</el-menu-item>
          <el-menu-item index="/product">关于公司</el-menu-item>
          <el-menu-item index="/case">汇修帮</el-menu-item>
          <el-menu-item index="/project">行业动态</el-menu-item>
        </el-menu>
      </el-header>
      <el-main>
        <router-view />
      </el-main>
      <div class="footer" v-if="$route.path !== '/project'">
        <div class="footer_icon w">
          <ul>
            <li>
              <div class="icons"><i class="el-icon-location-outline"></i></div>
              <p class="footer_address1">地址: 四川省成都市大邑县沙渠镇工业大道288号</p>
			  <p class="footer_address2">地址：四川省雅安市名山区成雅工业园区主干道</p>
            </li>
            <li>
              <div class="icons"><i class="el-icon-phone"></i></div>
              <p class="footer_address1">热线: 400-9018-198</p>
			  <p class="footer_address2">电话: 028-88289999</p>
            </li>
          </ul>
        </div>
        <div class="footer_copyright w">
          版权所有：四川赢信汇通实业有限公司 &nbsp;<a style="color: #fff;" href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2020026002号-1</a>
        </div>
      </div>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  methods: {
    handleSelect() {},
  },
  mounted(){

  }
};
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
}
html,
body {
  height: 100%;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
// .el-header {
//     box-shadow: 3px 2px 6px #999;
// }
.el-header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin: 0 auto;
  width: 100%;
  box-shadow: 3px 2px 6px #999;

  .logo {
    width: 280px;
    img {
      width: 50%;
      height: 60px;
    }
  }
}
.el-main {
  padding: 0 !important;
}

.footer {
  margin-top: 1px;
  height: 260px;
  background-color: #666;
}

.footer_icon {
  padding-top: 30px;
  height: 210px;
  border-bottom: 1px solid #eee;
}

.footer_icon ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer_icon ul li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.footer_copyright {
  text-align: center;
  padding-top: 15px;
  color: #999;
  font-size: 12px;
}
.icons {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border: 1px solid #eee;
  text-align: center;
  font-size: 40px;
  color: #eee;
}

.icons i {
  line-height: 100px;
}
.footer_address1 {
  margin-top: 20px;
  font-size: 14px;
  color: #eee;
}
.footer_address2 {
	margin-top: 2px;
	margin-bottom: 10px;
	font-size: 14px;
	color: #eee;
}
.w {
  width: 1200px;
  margin: 0 auto;
}
</style>
