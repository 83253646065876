// import Vue from 'vue'
// import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

// Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    meta: {
      title: '关于我们',
    },
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('../views/product.vue'),
    meta: {
      title: '产品中心',
    },
  },
  {
    path: '/case',
    name: 'case',
    component: () => import('../views/case.vue'),
    meta: {
      title: '汇修帮',
    },
  },
  {
    path: '/project',
    name: 'project',
    component: () => import('../views/project.vue'),
    meta: {
      title: '行业动态',
    },
  },
  {
    path: '*',
    meta: {
      title: '错误页面'
    },
    component: () => import('../views/err.vue')
  },
  // {
  //   path: '/didi',
  //   name: 'didi',
  //   component: () => import('../views/didi.vue'),
  //   meta: {
  //     title: '联系我们',
  //   },
  // }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
